import { documentoStore } from './../../../store/modules/documentos/index';
import { defineComponent } from 'vue';
import { DxColumn, DxExport } from 'devextreme-vue/ui/data-grid';
import DataGridFactory from '../../datagrid-factory/index.vue';
import Validators from '../../../helpers/validators-input';

export default defineComponent({
  name: 'XmlNaoProcessadas',
  setup(){
    const store = documentoStore();

    return {store, Validators}
  },
  components: {
    DataGridFactory,
    DxColumn,
    DxExport
  }
})